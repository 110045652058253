import React from 'react';
import styled from 'styled-components';

import { almostWhite, blue, blue70, primary } from 'styles/colors';
import Line1Icon from 'assets/icons/background_line_1.svg';
import Line2Icon from 'assets/icons/background_line_2.svg';
import {
  Container,
  FlexWrapper,
  H1,
  Logo,
  Regular,
  Seo,
  Svg,
  TextColor,
  TextWrapper,
} from 'components';
import { useQuery } from 'styles/breakpoints';

const OutOfStock = () => {
  const { isMobile } = useQuery();

  return (
    <>
      <Seo title="Out Of Stock | Pulsio" />
      <Background>
        {isMobile ? null : (
          <>
            <Line1 />
            <Line2 />
          </>
        )}
        <Container>
          <Logo />
          <Layout
            margin="0 auto"
            maxWidth="50rem"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <FlexWrapper
              justifyContent="center"
              margin={isMobile ? '1.5rem 0 0.5rem' : '6.125rem 0 0.625rem'}
              flexDirection="column"
              alignItems="center"
            >
              <Svg
                src="icons/leaves_2"
                width="52"
                height="36"
                viewBox="0 0 52 36"
                fill="none"
                style={{
                  display: 'block',
                }}
              />
            </FlexWrapper>
            <H1 color={blue} margin="0 auto 1.625rem">
              Oh no.
              <br />
              We’re out of stock!
            </H1>
            <Regular
              color={blue70}
              margin="0 auto 2.5rem"
              mobileFontSize="0.875rem"
            >
              Sorry for this inconvenience. Due to high demand, we’ve run out of
              a product in your treatment plan.
              <br />
              Once it’s restocked, we will send you an email reminder. Your card
              was not charged.
            </Regular>
            <Regular color={blue}>
              For more information, please contact Pulsio support:
              <br />
              <a
                href="mailto:hi@pulsio.me"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TextColor color={primary}>hi@pulsio.me</TextColor>
              </a>
            </Regular>
          </Layout>
        </Container>
      </Background>
    </>
  );
};

export default OutOfStock;

const Background = styled.div`
  position: relative;
  min-height: 100vh;
  padding-top: 1.25rem;
  background: ${almostWhite};
  overflow: hidden;
`;
const Layout = styled(FlexWrapper)`
  min-height: 75vh;
  text-align: center;
`;
const Line1 = styled(Line1Icon)`
  position: absolute;
  top: 0;
  left: 0;
`;
const Line2 = styled(Line2Icon)`
  position: absolute;
  bottom: 0;
  right: 0;
`;
